import React from 'react'
import '../styles/article.css'

const Article = ({ children }) => {
  return (
    <article className="flex flex-col gap-2 px-8 lg:px-44 xl:px-80 2xl:px-96 py-4 bg-stone-100">
      {children}
    </article>
  )
}

export default Article