import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { getImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Article from '../components/article'

const Page = ({ data }) => {
  const image = getImage(data.mdx.frontmatter.hero_image)
  return (
    <Layout pageTitle={data.mdx.frontmatter.title} hero={{
      title: data.mdx.frontmatter.title,
      image,
      alt: data.mdx.frontmatter.hero_image_alt
    }}>
      <Article>
        <MDXRenderer>
          {data.mdx.body}
        </MDXRenderer>
      </Article>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`